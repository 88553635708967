<template>
    <b-overlay :show="loading" rounded="sm">
        <b-row>
            <b-col cols="12">
                <!-- 1 -->
                <b-card no-body class="mb-4">
                    <!-- Search Control -->
                    <b-card-body class="p-3 d-flex justify-content-between">
                        <b-form-group class="mb-2 w-25">
                            <b-input-group>
                                <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    placeholder="Type to Search"
                                ></b-form-input>

                                <b-input-group-append>
                                    <b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-card-body>
                    <!-- End Section -->

                    <!-- Data Table -->
                    <b-table
                        style="min-height: 250px"
                        show-empty
                        responsive
                        small
                        class="mb-0"
                        head-variant="light"
                        :items="items"
                        :filter="filter"
                        :fields="fields"
                        hover
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        no-local-sorting
                    >
                        <template #cell(id)="data"> #{{ data.item.id }} </template>
                        <template #cell(creationDate)="data"> {{ $formatDate(data.item.creationDate) }} </template>
                        <template #cell(loanAmount)="data"> {{ $formatCurrency(data.item.loanAmount) }} </template>

                        <template #cell(action)="data">
                            <b-button variant="primary" @click="goToDetails(data.item)"> View Details </b-button>
                        </template>
                    </b-table>
                    <!-- End Section -->

                    <!-- Pagination -->
                    <div class="p-3 d-flex align-items-center">
                        <b-form-group
                            label="Per page"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="9"
                            label-size="sm"
                            label-class="fw-medium"
                            class="mb-0"
                        >
                            <b-form-select
                                @change="getData"
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                            ></b-form-select>
                        </b-form-group>
                        <div class="ml-auto">
                            <b-pagination
                                @input="getData"
                                v-model="currentPage"
                                :total-rows="2000"
                                :per-page="perPage"
                                align="fill"
                                class="my-0"
                            ></b-pagination>
                        </div>
                    </div>
                    <!-- End Section -->
                </b-card>
            </b-col>
        </b-row>
    </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'applications',

    data: () => ({
        page: {
            title: 'applications',
        },
        sortBy: '',
        sortDesc: false,
        sortColumns: {
            id: '[CreatedLoans].[Id]',
            loanId: '[CreatedLoans].[LoanId]',
        },
        fields: [
            {
                key: 'id',
                sortable: true,
            },
            {
                key: 'loanName',
                label: 'Loan Name',
                sortable: true,
            },
            {
                key: 'loanAmount',
                label: 'Amount',
                sortable: true,
            },
            {
                key: 'accountState',
                label: 'State',
                sortable: true,
            },
            {
                key: 'paymentMethod',
                label: 'Payment Method',
                sortable: true,
            },
            {
                key: 'creationDate',
                label: 'Date',
                sortable: true,
            },
            {
                key: 'action',
                label: 'Action',
                sortable: true,
            },
        ],
        loading: false,
        filter: null,
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],
        items: [],
        routes: [
            {
                path: '/loans/active',
                status: 'ACTIVE',
            },
            {
                path: '/loans/approved',
                status: 'APPROVED',
            },
            {
                path: '/loans/pending-approval',
                status: 'PENDING_APPROVAL',
            },
            {
                path: '/loans/close',
                status: 'CLOSED',
            },
        ],
    }),
    components: {},
    methods: {
        async getData() {
            this.loading = true;
            const response = await this.$store.dispatch('mambu/get_mambuLoans', {
                skip: this.currentPage,
                take: this.perPage,
            });
            this.items = response?.dataResult?.payload ?? [];
            this.totalRows = response?.dataRecords ?? 0;
            this.loading = false;
        },
        toJson(str) {
            const jsonString = JSON.parse(str);

            return jsonString;
        },
        goToDetails(item) {
            this.$router.push(`all/${item.id}`);
        },
    },
    computed: {
        ...mapGetters({
            customers: 'customer/screening/getScreeningByBank',
            totalCustomers: 'customer/screening/getTotalScreeningByBank',
        }),
    },
    watch: {
        '$route.path'() {
            const route = this.routes.find((el) => el.path == this.$route.path);
            if (route) {
                this.getData();
            }
        },
        sortBy() {
            this.getData();
        },
        sortDesc() {
            this.getData();
        },
    },
    mounted() {},
    created() {
        this.getData();
    },
};
</script>
